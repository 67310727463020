/**
 * Container component for the ranks table.
 */

import { useEffect, useRef, useState } from 'react';
import { sortDataByColumn } from '../../utils/tables';
import type { TableColumn } from '../../types/TableColumn';
import type { Rank } from '../../types/Rank';
import { getRanks } from '../../utils/api';
import { useData } from '../../hooks/useData';
import TableLoader from './TableLoader';
import TableContainer from './TableContainer';
import TableBody from './TableBody';
import TableHead from './TableHead';
import TableHeader from './TableHeader';
import Table from './Table';
import RefreshButton from '../buttons/RefreshButton';
import { getMissingLocationIds } from '../../utils/locations';
import DropdownRegionMultiple from '../dropdowns/DropDownRegionMuli';
import type { RegionsStats } from '../../types/RegionsStats';
import { app } from '../../firebase';
import {
  collection,
  doc,
  onSnapshot,
  getFirestore,
  type Unsubscribe,
} from 'firebase/firestore';

const tableTitle = 'Location Ranks';

export default function RanksTable(): JSX.Element {
  const {
    selectedFranchise,
    selectedDates,
    selectedLocations,
    toggled,
    userLocations,
  } = useData();

  const [columns, setColumns] = useState<TableColumn[]>([
    { label: 'Rank', key: 'rank', type: 'number' },
    {
      label: 'Location',
      key: 'locationName',
      type: 'link',
      href: '/cameras/?lid=locationId',
    },
    { label: 'Average', key: 'averageServiceTime', type: 'seconds' },
    { label: 'Customer Count', key: 'customerCount', type: 'number' },
    {
      label: 'Difference From Best Day',
      key: 'differenceFromBestDay',
      type: 'secondsDifference',
    },
    {
      label: 'WOW Change',
      key: 'weekOverWeekChangeInTime',
      type: 'secondsDifference',
    },
    { label: 'Rank Change', key: 'rankChange', type: 'numberDifference' },
  ]); // Use Column[] as the type
  const [data, setData] = useState<Rank[]>([]);
  const [displayData, setDisplayData] = useState<Rank[]>([]);
  const [sortColumn, setSortColumn] = useState(columns[0]);
  const [sortDirection, setSortDirection] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRegions, setSelectedRegions] = useState<any[]>([]);
  const [regions, setRegions] = useState<any[]>([]);
  const dataRef = useRef<Rank[]>([]); // Holds all rows data
  const [, setDummyState] = useState(0); // Used to trigger selective updates
  // Use another ref to avoid infinite rerendering loop
  const isUpdatingRef = useRef(false);

  function getDistinctRegions(rawData: RegionsStats[]): any[] {
    if (rawData.length === 0) return [];
    const regions: Record<string, string> = {};
    const cols = [];
    // Get all possible regions.
    for (const data of rawData) {
      for (const region of data.regions) {
        regions[region.key] = region.displayName;
      }
    }

    for (const key in regions) {
      cols.push({
        id: key,
        value: regions[key],
      });
    }

    // Sort the columns by label.
    cols.sort((a, b) => a.id.localeCompare(b.id));

    return cols;
  }

  function handleSelectedRegionsChange(id: string): void {
    let newSelectedRegions;

    const regionInSelected = selectedRegions.find((region) => region.id === id);

    if (regionInSelected !== undefined) {
      newSelectedRegions = selectedRegions.filter((region) => region.id !== id);
      setSelectedRegions(newSelectedRegions);
    } else {
      const region = regions.find((region) => region.id === id);
      newSelectedRegions = [...selectedRegions, region];
      setSelectedRegions([...selectedRegions, region]);
    }

    const regionKeys = newSelectedRegions.map((r) => r.id);
    const newData = data;

    newData.forEach((location, index) => {
      const values: number[] = [];

      location.regionsAgg.regions.forEach((r) => {
        if (regionKeys.includes(r.key)) {
          values.push(r.timeSpent);
        } else {
          values.push(0);
        }
      });
      newData[index].averageServiceTime = values.reduce((a, b) => a + b);
    });

    const col = columns.find((c) => c.key === 'averageServiceTime');
    if (col !== undefined) {
      backGroundSort(col, newData);
    }
  }

  async function fetchData(): Promise<void> {
    if (selectedFranchise === null) {
      return;
    }
    setIsLoading(true);

    const oneWeekAgo = new Date(selectedDates[0]);
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    const regionEndDate = new Date(selectedDates[0]);
    regionEndDate.setDate(regionEndDate.getDate() + 1);

    const rankDataPayload = await getRanks(
      selectedFranchise.id,
      oneWeekAgo,
      selectedDates[0],
      userLocations
        .filter((location) => selectedLocations.includes(location))
        .map((location) => location.id),
      toggled === 'drive-thru'
    );

    const rankData = rankDataPayload.rankData;
    const regionData = rankDataPayload.regionForRankData;
    const modifiedData = addBlankLocations(rankData);

    modifiedData.forEach((location, index) => {
      const matchingRegionEntries = regionData.filter(
        (regLocation) => regLocation.locationId === location.locationId
      );

      if (matchingRegionEntries.length > 0) {
        const matchingRegionEntry: RegionsStats = {
          locationId: location.locationId,
          locationName: location.locationName,
          regions: matchingRegionEntries.map(entry => ({
            key: entry.region,
            displayName: entry.locationName,
            timeSpent: entry.averageServiceTime
          }))
        };
        modifiedData[index].regionsAgg = matchingRegionEntry;
      } else {
        modifiedData[index].regionsAgg = {
          locationId: location.locationId,
          locationName: location.locationName,
          regions: []
        };
        modifiedData[index].averageServiceTime = 0;
      }
    });

    const distinctRegions = getDistinctRegions(modifiedData.map((d) => d.regionsAgg));
    setSelectedRegions(distinctRegions);
    setRegions(distinctRegions);

    const col = columns.find((c) => c.key === 'averageServiceTime');
    if (col !== undefined) {
      backGroundSort(col, modifiedData);
    }
    setIsLoading(false);
  }

  function setColumnsFunction(): void {
    const shouldIncludeCurrentCount =
      toggled === 'lobby' &&
      new Date().toDateString() === new Date(selectedDates[0]).toDateString();
    const shouldIncludeOpenAndClose =
      typeof window !== 'undefined' && window.location.hostname.includes('avs');
    const baseColumns = [
      { label: 'Rank', key: 'rank', type: 'number' },
      {
        label: 'Location',
        key: 'locationName',
        type: 'link',
        href: '/cameras/?lid=locationId',
      },
      { label: 'Average', key: 'averageServiceTime', type: 'seconds' },
      { label: 'Customer Count', key: 'customerCount', type: 'number' },
      ...(shouldIncludeCurrentCount
        ? [{ label: 'Current Count', key: 'currentCount', type: 'number' }]
        : []),
      {
        label: 'Difference From Best Day',
        key: 'differenceFromBestDay',
        type: 'secondsDifference',
      },
      {
        label: 'WOW Change',
        key: 'weekOverWeekChangeInTime',
        type: 'secondsDifference',
      },
      { label: 'Rank Change', key: 'rankChange', type: 'numberDifference' },
      ...(shouldIncludeOpenAndClose
        ? [{ label: 'Open Time', key: 'openTime', type: 'timeOpen' },
          { label: 'Close Time', key: 'closeTime', type: 'timeClose' }]
        : []),
    ];

    // if (shouldIncludeCurrentCount) {
    //   baseColumns.push({ label: 'Current Count', key: 'currentCount', type: 'number' });
    // }

    setColumns(baseColumns);
  }

  // Fetch the data on load.
  useEffect(() => {
    setColumnsFunction();
    fetchData().catch((err) => {
      console.error(err);
    });
  }, []);

  // Fetch the data when the dates, franchise, or toggle change.
  useEffect(() => {
    setColumnsFunction();
    fetchData().catch((err) => {
      console.error(err);
    });
  }, [selectedDates[0], selectedFranchise, toggled, selectedLocations]);

  // Set the data to display based on the selected locations.
  useEffect(() => {
    const unsubscribes: Unsubscribe[] = [];
    const firestore = getFirestore(app);

    dataRef.current = [...data];

    const selected = selectedLocations.map((location) => location.displayName);
    selectedLocations.forEach((location) => {
      const locationDoc = doc(collection(firestore, 'location'), location.id);
      const unsub = onSnapshot(locationDoc, (docSnapshot) => {
        const fbData = docSnapshot.data();
        if (fbData?.current_volume != null) {
          // Find the relevant location in dataRef and update its value
          const index = dataRef.current.findIndex(
            (row) => row.locationId === location.id
          );
          if (index !== -1) {
            dataRef.current[index].currentCount = fbData.current_volume;

            // Trigger a minor update without a full re-render
            if (!isUpdatingRef.current) {
              isUpdatingRef.current = true;
              setDummyState((prev) => prev + 1); // Minor state update
              isUpdatingRef.current = false;
            }
          }
        }
      });
      unsubscribes.push(unsub);
    });

    // setDisplayData(data.filter((row) => selected.includes(row.locationName)));

    return () => {
      unsubscribes.forEach((unsubscribe) => {
        unsubscribe();
      });
    };
  }, [selectedLocations, data]);

  // UseEffect to set displayData initially based on dataRef, without rerendering every update
  useEffect(() => {
    setDisplayData(dataRef.current);
  }, [data, selectedLocations]);

  // Sort a column.
  function handleSort(column: TableColumn): void {
    const newData = sortDataByColumn(dataRef.current, column, sortDirection);
    dataRef.current = [...newData]; // Populate ref initially
    setData(newData);
    setSortColumn(column);
    setSortDirection(sortDirection * -1);
  }

  // Add blank locations to the display data.
  function addBlankLocations(data: Rank[]): Rank[] {
    const missingLocationIds = getMissingLocationIds(
      selectedLocations.map((location) => location.id),
      data.map((row) => row.locationId)
    );

    for (const id of missingLocationIds) {
      const location = selectedLocations.find((location) => location.id === id);
      const regions =
        toggled === 'lobby'
          ? (location?.lobbyConfiguration?.regions as any[])
          : (location?.dtConfiguration?.regions as any[]);
      if (location !== undefined && regions !== undefined) {
        for (const region of regions) {
          region.timeSpent = 0;
        }
        data.push({
          rank: 0,
          locationName: location.displayName,
          averageServiceTime: 0,
          customerCount: 0,
          differenceFromBestDay: 0,
          weekOverWeekChangeInTime: 0,
          rankChange: 0,
          currentCount: 0,
          locationId: location.id,
          regionsAgg: {
            locationId: location.id,
            locationName: location.displayName,
            regions,
          },
        } as Rank);
      }
    }
    return data;
  }

  function backGroundSort(column: TableColumn, oldData: Rank[]): void {
    const newData = sortDataByColumn(oldData, column, 1);
    newData.forEach((row: Rank, index) => (newData[index].rank = index + 1));
    setData(newData);
    setSortColumn(column);
    setSortDirection(1);
  }

  if (selectedFranchise === null || isLoading) {
    return (
      <TableLoader
        columns={columns.map((column) => column.label)}
        title={tableTitle}
        handleRefresh={() => {
          fetchData().catch((err) => {
            console.error(err);
          });
        }}
      />
    );
  }

  return (
    <TableContainer dataCy="ranks-table">
      <TableHeader title={tableTitle}>
        <DropdownRegionMultiple
          regionStats={regions}
          selectedRegionStats={selectedRegions}
          handlSelectedRegionStatsChange={handleSelectedRegionsChange}
        />
        <RefreshButton
          handleRefresh={() => {
            fetchData().catch((err) => {
              console.error(err);
            });
          }}
          dataCy="refresh-button-ranks"
        />
      </TableHeader>
      <Table>
        <TableHead
          columns={columns}
          handleSort={handleSort}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
        />
        <TableBody columns={columns} data={displayData} />
      </Table>
    </TableContainer>
  );
}

// /**
//  * Container component for the ranks table.
//  */

// import { useEffect, useRef, useState } from 'react';
// import { sortDataByColumn } from '../../utils/tables';
// import type { TableColumn } from '../../types/TableColumn';
// import type { Rank } from '../../types/Rank';
// import { getRanks, getRegionsStats } from '../../utils/api';
// import { useData } from '../../hooks/useData';
// import TableLoader from './TableLoader';
// import TableContainer from './TableContainer';
// import TableBody from './TableBody';
// import TableHead from './TableHead';
// import TableHeader from './TableHeader';
// import Table from './Table';
// import RefreshButton from '../buttons/RefreshButton';
// import { getMissingLocationIds } from '../../utils/locations';
// import DropdownRegionMultiple from '../dropdowns/DropDownRegionMuli';
// import type { RegionsStats } from '../../types/RegionsStats';
// import { app, } from '../../firebase';
// import {
//   collection,
//   doc,
//   onSnapshot,
//   getFirestore,
//   type Unsubscribe
// } from 'firebase/firestore';

// const tableTitle = 'Location Ranks';

// const columns = [
//   { label: 'Rank', key: 'rank', type: 'number' },
//   {
//     label: 'Location',
//     key: 'locationName',
//     type: 'link',
//     href: '/cameras/?lid=locationId',
//   },
//   { label: 'Average', key: 'averageServiceTime', type: 'seconds' },
//   { label: 'Customer Count', key: 'customerCount', type: 'number' },
//   { label: 'Current Count', key: 'currentCount', type: 'number' },
//   {
//     label: 'Difference From Best Day',
//     key: 'differenceFromBestDay',
//     type: 'secondsDifference',
//   },
//   {
//     label: 'WOW Change',
//     key: 'weekOverWeekChangeInTime',
//     type: 'secondsDifference',
//   },
//   { label: 'Rank Change', key: 'rankChange', type: 'numberDifference' },
// ];

// // only include if on avs
// if (typeof window !== 'undefined' && window.location.hostname.includes('avs')) {
//   columns.push(
//   { label: 'Open Time', key: 'openTime', type: 'timeOpen' },
//   { label: 'Close Time', key: 'closeTime', type: 'timeClose' }
//   );
// }

// export default function RanksTable(): JSX.Element {
//   const {
//     selectedFranchise,
//     selectedDates,
//     selectedLocations,
//     toggled,
//     userLocations,
//   } = useData();

//   const [data, setData] = useState<Rank[]>([]);
//   const [displayData, setDisplayData] = useState<Rank[]>([]);
//   const [sortColumn, setSortColumn] = useState(columns[0]);
//   const [sortDirection, setSortDirection] = useState(1);
//   const [isLoading, setIsLoading] = useState(true);
//   const [selectedRegions, setSelectedRegions] = useState<any[]>([]);
//   const [regions, setRegions] = useState<any[]>([]);
//   const dataRef = useRef<Rank[]>([]); // Holds all rows data
//   const [, setDummyState] = useState(0); // Used to trigger selective updates
//   // Use another ref to avoid infinite rerendering loop
//   const isUpdatingRef = useRef(false);

//   function getDistinctRegions(rawData: RegionsStats[]): any[] {
//     if (rawData.length === 0) return [];
//     const regions: Record<string, string> = {};
//     const cols = [];
//     // Get all possible regions.
//     for (const data of rawData) {
//       for (const region of data.regions) {
//         regions[region.key] = region.displayName;
//       }
//     }

//     for (const key in regions) {
//       cols.push({
//         id: key,
//         value: regions[key],
//       });
//     }

//     // Sort the columns by label.
//     cols.sort((a, b) => a.id.localeCompare(b.id));

//     return cols;
//   }

//   function handleSelectedRegionsChange(id: string): void {
//     let newSelectedRegions;

//     const regionInSelected = selectedRegions.find((region) => region.id === id);

//     if (regionInSelected !== undefined) {
//       newSelectedRegions = selectedRegions.filter((region) => region.id !== id);
//       setSelectedRegions(newSelectedRegions);
//     } else {
//       const region = regions.find((region) => region.id === id);
//       newSelectedRegions = [...selectedRegions, region];
//       setSelectedRegions([...selectedRegions, region]);
//     }

//     const regionKeys = newSelectedRegions.map((r) => r.id);
//     const newData = data;

//     newData.forEach((location, index) => {
//       const values: number[] = [];

//       location.regionsAgg.regions.forEach((r) => {
//         if (regionKeys.includes(r.key)) {
//           values.push(r.timeSpent);
//         } else {
//           values.push(0);
//         }
//       });
//       newData[index].averageServiceTime = values.reduce((a, b) => a + b);
//     });

//     const col = columns.find((c) => c.key === 'averageServiceTime');
//     if (col !== undefined) {
//       backGroundSort(col, newData);
//     }
//   }

//   async function fetchData(): Promise<void> {
//     if (selectedFranchise === null) {
//       return;
//     }
//     setIsLoading(true);

//     const oneWeekAgo = new Date(selectedDates[0]);
//     oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
//     const regionEndDate = new Date(selectedDates[0]);
//     regionEndDate.setDate(regionEndDate.getDate() + 1);

//     const regionData = await getRegionsStats(
//       selectedFranchise.id,
//       selectedDates[0],
//       regionEndDate,
//       userLocations
//         .filter((location) => selectedLocations.includes(location))
//         .map((location) => location.id),
//       toggled === 'drive-thru'
//     );

//     const rankData = await getRanks(
//       selectedFranchise.id,
//       oneWeekAgo,
//       selectedDates[0],
//       userLocations
//         .filter((location) => selectedLocations.includes(location))
//         .map((location) => location.id),
//       toggled === 'drive-thru'
//     );

//     const modifiedData = addBlankLocations(rankData);

//     // Map through the data to create a new updated array
//     const updatedData = modifiedData.map((location) => {
//       const matchingRegionEntry = regionData.find(
//         (regLocation) => regLocation.locationId === location.locationId
//       );

//       if (matchingRegionEntry !== undefined) {
//         if (matchingRegionEntry.regions.length === 0) {
//           location.averageServiceTime = 0;
//         }

//         location.regionsAgg = matchingRegionEntry;

//         // Check if the selected date is today
//         const isToday = new Date().toDateString() === new Date(selectedDates[0]).toDateString();

//         // Log the date comparison and currentCount to see if it's working as expected
//         console.log('isToday:', isToday);
//         console.log('Location:', location.locationName);
//         console.log('currentCount before update:', location.currentCount);
//         console.log('full location', location)

//         // Return a new location object with updated currentCount
//         return {
//           ...location,
//           currentCount: isToday ? location.currentCount : 0,  // Set to null or 0 based on the date condition
//         };
//       }

//       // If no region data matches, return the original location
//       return location;
//     });

//     // Update the states with the new data
//     setSelectedRegions(
//       getDistinctRegions(updatedData.map((d) => d.regionsAgg))
//     );
//     setRegions(getDistinctRegions(updatedData.map((d) => d.regionsAgg)));

//     const col = columns.find((c) => c.key === 'averageServiceTime');
//     if (col !== undefined) {
//       backGroundSort(col, updatedData);
//     }

//     setIsLoading(false);
//   }

//   // Fetch the data on load.
//   useEffect(() => {
//     fetchData().catch((err) => {
//       console.error(err);
//     });
//   }, []);

//   // Fetch the data when the dates, franchise, or toggle change.
//   useEffect(() => {
//     fetchData().catch((err) => {
//       console.error(err);
//     });
//   }, [selectedDates[0], selectedFranchise, toggled, selectedLocations]);

//   // Set the data to display based on the selected locations.
//   useEffect(() => {
//     const unsubscribes: Unsubscribe[] = [];
//     const firestore = getFirestore(app);

//     dataRef.current = [...data];

//     const selected = selectedLocations.map((location) => location.displayName);
//     selectedLocations.forEach(location => {
//       const locationDoc = doc(collection(firestore, 'location'), location.id);
//       const unsub = onSnapshot(locationDoc, (docSnapshot) => {
//         const fbData = docSnapshot.data();
//         if (fbData?.current_volume != null) {
//           // Find the relevant location in dataRef and update its value
//           const index = dataRef.current.findIndex(
//             (row) => row.locationId === location.id
//           );
//           if (index !== -1) {
//             dataRef.current[index].currentCount = fbData.current_volume;

//             // console.log(fbData.current_volume);
//             // Trigger a minor update without a full re-render
//             if (!isUpdatingRef.current) {
//               isUpdatingRef.current = true;
//               setDummyState((prev) => prev + 1); // Minor state update
//               isUpdatingRef.current = false;
//             }
//           }
//         }
//       });
//       unsubscribes.push(unsub);
//     });

//     // setDisplayData(data.filter((row) => selected.includes(row.locationName)));

//     return () => {
//       unsubscribes.forEach(unsubscribe => { unsubscribe(); });
//     };
//   }, [selectedLocations, data, selectedDates]);

//   // UseEffect to set displayData initially based on dataRef, without rerendering every update
//   useEffect(() => {
//     setDisplayData(dataRef.current);
//   }, [data, selectedLocations]);

//   // Sort a column.
//   function handleSort(column: TableColumn): void {
//     const newData = sortDataByColumn(dataRef.current, column, sortDirection);
//     dataRef.current = [...newData]; // Populate ref initially
//     setData(newData);
//     setSortColumn(column);
//     setSortDirection(sortDirection * -1);
//   }

//   // Add blank locations to the display data.
//   function addBlankLocations(data: Rank[]): Rank[] {
//     const missingLocationIds = getMissingLocationIds(
//       selectedLocations.map((location) => location.id),
//       data.map((row) => row.locationId)
//     );

//     for (const id of missingLocationIds) {
//       const location = selectedLocations.find((location) => location.id === id);
//       const regions =
//         toggled === 'lobby'
//           ? (location?.lobbyConfiguration?.regions as any[])
//           : (location?.dtConfiguration?.regions as any[]);
//       if (location !== undefined && regions !== undefined) {
//         for (const region of regions) {
//           region.timeSpent = 0;
//         }
//         data.push({
//           rank: 0,
//           locationName: location.displayName,
//           averageServiceTime: 0,
//           customerCount: 0,
//           differenceFromBestDay: 0,
//           weekOverWeekChangeInTime: 0,
//           rankChange: 0,
//           currentCount: 0,
//           locationId: location.id,
//           regionsAgg: {
//             locationId: location.id,
//             locationName: location.displayName,
//             regions,
//           },
//         } as Rank);
//       }
//     }
//     return data;
//   }

//   function backGroundSort(column: TableColumn, oldData: Rank[]): void {
//     const newData = sortDataByColumn(oldData, column, 1);
//     newData.forEach((row: Rank, index) => (newData[index].rank = index + 1));
//     setData(newData);
//     setSortColumn(column);
//     setSortDirection(1);
//   }

//   if (selectedFranchise === null || isLoading) {
//     return (
//       <TableLoader
//         columns={columns.map((column) => column.label)}
//         title={tableTitle}
//         handleRefresh={() => {
//           fetchData().catch((err) => {
//             console.error(err);
//           });
//         }}
//       />
//     );
//   }

//   return (
//     <TableContainer dataCy="ranks-table">
//       <TableHeader title={tableTitle}>
//         <DropdownRegionMultiple
//           regionStats={regions}
//           selectedRegionStats={selectedRegions}
//           handlSelectedRegionStatsChange={handleSelectedRegionsChange}
//         />
//         <RefreshButton
//           handleRefresh={() => {
//             fetchData().catch((err) => {
//               console.error(err);
//             });
//           }}
//           dataCy="refresh-button-ranks"
//         />
//       </TableHeader>
//       <Table>
//         <TableHead
//           columns={columns}
//           handleSort={handleSort}
//           sortColumn={sortColumn}
//           sortDirection={sortDirection}
//         />
//         <TableBody columns={columns} data={displayData} />
//       </Table>
//     </TableContainer>
//   );
// }
